import { Fab, FabProps } from '@mui/material';
import React from 'react';
import theme from '../../theme/default';
import { useAppSelector } from '../../state/hooks';
import { selectThemeMode } from '../../state/contentSlice';

const StyledFab: React.FC<FabProps> = props => {
    const themeMode = useAppSelector(selectThemeMode);
    const fabStyles = {
        position: 'absolute',
        margin: theme.sizes.marginSmall,
        zIndex: 9000,

        backgroundColor: theme.palette[themeMode].colors.bg,
        color: theme.palette[themeMode].colors.fg,

        ':hover': {
            backgroundColor: theme.palette[themeMode].colors.accent2
        }
    } as const;

    return (
        <Fab sx={{...fabStyles, ...props.sx}} className={props.className} onClick={props.onClick}>
            {props.children}
        </Fab>
    )
}

export default StyledFab;
