import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '../../state/hooks';
import { selectThemeMode } from '../../state/contentSlice';
import theme from '../../theme/default';

function TitleBar() {
    const themeMode = useAppSelector(selectThemeMode);
    const { fg, bg } = theme.palette[themeMode].colors;
    const logo = theme.palette[themeMode].logos.banner;

    const titleBarCss = {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '9vh',
        paddingTop: '3vh',
        paddingBottom: '3vh',

        backgroundColor: bg,
        color: fg,

        boxShadow: theme.boxShadows.normal,
        zIndex: 9999,

        img: {
            height: '100%'
        }
    } as const;

    return (
        <Box sx={titleBarCss}>
            <img src={logo} alt={'a cloud with the text BlueWindly'}/>
        </Box>
    )
}

export default TitleBar;
