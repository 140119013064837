import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "./store"

export type Point = {
    latitude: number,
    longitude: number,
    altitude: number
}

type TMapViewSlice = {
    currentPosition: Point,
    zoomLevel: number
}

const initialState: TMapViewSlice = {
    currentPosition: {
        longitude: -80.19366,
        latitude: 25.77427,
        altitude: 0
    },
    zoomLevel: 12
}

export const mapViewSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setCurrentPosition: (state, action: PayloadAction<Point>) => { state.currentPosition = action.payload; },
        setZoomLevel: (state, action: PayloadAction<number>) => { state.zoomLevel = action.payload; }
    }
});

export const { setCurrentPosition, setZoomLevel } = mapViewSlice.actions;
export const selectCurrentPosition = (state: RootState) => state.map.currentPosition;
export const selectCurrentMapZoom = (state: RootState) => state.map.zoomLevel;
export default mapViewSlice.reducer;
