import axios from "axios";
import INearbyStation from "../INearbyStation";
import IStationInfo from "../IStationInfo";
import IPointInfo from "../IPointInfo";
import IForecastInfo from '../IForecastInfo';

const URI_BASE = 'https://api.weather.gov/';

export async function getPointInfo(lat: number, lng: number): Promise<IPointInfo> {
    const result = await axios.get(`${URI_BASE}/points/${lat}%2C${lng}`);
    const { forecast, forecastHourly, forecastOffice, gridId, observationStations } = result.data.properties;

    return {
        forecast,
        forecastHourly,
        forecastOffice,
        gridId,
        observationStations
    }
}

export async function getNearbyStations(stationsUri: string): Promise<INearbyStation[]> {
    const result = await axios.get(stationsUri);
    const { features } = result.data;
    const stations = features.map((f: any): INearbyStation => {
        return {
            id: f.id,
            lat: f.geometry.coordinates[1],
            lng: f.geometry.coordinates[0],
            elevation: f.properties.elevation.value,
            stationIdentifier: f.properties.stationIdentifier,
            name: f.properties.name,
            timezone: f.properties.timeZone,
            forecast: f.properties.forecast
        }
    })

    return stations;
}

export async function getForecast(forecastUri: string): Promise<IForecastInfo[]> {
    const result = await axios.get(forecastUri);
    const { periods } = result.data.properties;
    return periods as IForecastInfo[]
}

export async function getStationInfo(stationId: string): Promise<IStationInfo> {
    const result = await axios.get(`${URI_BASE}/stations/${stationId}/observations/latest?require_qc=true`);
    const { properties } = result.data;

    return {
        barometricPressure: properties.barometricPressure.value,
        dewPoint: properties.dewpoint.value,
        temperature: properties.temperature.value,
        textDescription: properties.textDescription,
        timestamp: properties.timestamp,
        visibility: properties.visibility.value,
        windDirection: properties.windDirection.value,
        windSpeed: properties.windSpeed.value
    }
}
