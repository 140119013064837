import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { selectCurrentPosition, setCurrentPosition, setZoomLevel } from '../../state/mapViewSlice';
import { selectThemeMode } from '../../state/contentSlice';
import React, { FC, useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { getForecast, getNearbyStations, getPointInfo } from '../../wxApi/providers/wxgov';
import { setForecastInfo, setNearbyStations, setPointInfo } from '../../state/weatherSlice';
import L from 'leaflet';

const baseLayers = {
    light: new L.TileLayer('https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}'),
    dark: new L.TileLayer('https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}')
}

const MapLogic: FC = () => {
    const dispatch = useAppDispatch();
    const { latitude, longitude } = useAppSelector(selectCurrentPosition);
    const themeMode = useAppSelector(selectThemeMode);
    const [ point, setPoint ] = useState<number[]>([latitude, longitude]);

    const map = useMapEvents({
        load() {
            map.locate();
        },

        locationfound(e) {
            const { lat, lng } = e.latlng;
            setPoint([lat, lng]);
            map.flyTo({lat, lng});
        },

        moveend() {
            const { lat, lng } = map.getCenter();
            setPoint([lat, lng]);
        },

        zoomend() {
            const z = map.getZoom();
            dispatch(setZoomLevel(z));
        }
    });

    useEffect(() => {
        const lat = point[0];
        const lng = point[1];

        dispatch(setCurrentPosition({
            latitude: lat,
            longitude: lng,
            altitude: 0
        }));

        getPointInfo(lat, lng).then((p) => {
            dispatch(setPointInfo(p));
            getNearbyStations(p.observationStations).then((stations) => {
                dispatch(setNearbyStations(stations));
            })
            getForecast(p.forecast).then(forecastInfos => {
                dispatch(setForecastInfo(forecastInfos))
            })
        })
    }, [dispatch, point]);

    useEffect(() => {
        map.addLayer(baseLayers[themeMode]);
        return () => {
            map.removeLayer(baseLayers[themeMode]);
        }
    }, [themeMode, map])

    return <></>
}

export default MapLogic;
