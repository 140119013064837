import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import mapViewReducer from './mapViewSlice';
import contentReducer from './contentSlice';
import weatherReducer from './weatherSlice';

export const store = configureStore({
  reducer: {
    map: mapViewReducer,
    weather: weatherReducer,
    content: contentReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
