import React from 'react'
import { useAppSelector } from '../../state/hooks'
import MapView from '../map-view'
import { CurrentView, selectCurrentView } from '../../state/contentSlice'
import { makeStyles } from '@mui/styles';
import { ForecastItemList } from '../forecast';
import { selectForecast } from '../../state/weatherSlice';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        zIndex: 900,

        width: '100%',
        maxHeight: '78.19vh', /* Todo: figure out this hack... */

        overflow: 'auto'
    }
})

function Content() {
    const currentView = useAppSelector(selectCurrentView);
    const forecast = useAppSelector(selectForecast);

    const classes = useStyles();

    let viewContent = <div>Loading...</div>

    switch (currentView) {
        case CurrentView.CURRENT_CONDITIONS:
            viewContent = <MapView />
            break;

        case CurrentView.FORECAST:
            viewContent = <ForecastItemList items={forecast} />;
            break;
    }

    return (
        <div className={classes.root}>
            {viewContent}
        </div>
    )
}

export default Content;
