import React from 'react';

import MapLogic from './map-logic';
import { LocationFab, DisplayModeFab } from '../floating-action-buttons';
import { MapContainer, TileLayer } from 'react-leaflet';

import { selectCurrentMapZoom, selectCurrentPosition } from '../../state/mapViewSlice';
import { useAppSelector } from '../../state/hooks';
import { makeStyles } from '@mui/styles';
import CurrentConditions from '../currentConditions';
import Crosshair from './crosshair';

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: 'flex',
        justifyItems: 'center'
    }
})

function MapView () {
    const { latitude, longitude } = useAppSelector(selectCurrentPosition);
    const zoomLevel = useAppSelector(selectCurrentMapZoom);

    const classes = useStyles();

    // https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}
    // https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png

    return (
        <MapContainer center={[latitude, longitude]} zoom={zoomLevel} className={classes.root}>
            <MapLogic />
            <LocationFab />
            <DisplayModeFab />
            <Crosshair />
            <TileLayer url={`https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}`} />
            <CurrentConditions />
        </MapContainer>
    );
}

export default MapView;
