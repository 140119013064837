import MyLocationIcon from '@mui/icons-material/MyLocation';
import React, { useCallback } from 'react';
import { useMap } from "react-leaflet";
import StyledFab from './styled-fab';

function LocationFab() {
    const map = useMap();
    const handleClick = useCallback(() => {
        map.locate();
    }, [map]);

    return (
        <StyledFab onClick={handleClick} className={'leaflet-control leaflet-right'} sx={{ top: 0 }}>
            <MyLocationIcon />
        </StyledFab>
    )
}

export default LocationFab;
