import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
    changeThemeMode,
    changeToView,
    CurrentView, selectThemeMode
} from '../../state/contentSlice';
import WbSunnySharpIcon from '@mui/icons-material/WbSunnySharp';
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import theme from '../../theme/default';

function NavBar() {
    const dispatch = useAppDispatch();
    const themeMode = useAppSelector(selectThemeMode);

    const navCss = {
        width: '100%',
        boxShadow: theme.boxShadows.normal,
        backgroundColor: theme.palette[themeMode].colors.bg,
        zIndex: 9999
    } as const;

    const buttonCss = {
        color: theme.palette[themeMode].colors.fg,
        textShadow: theme.palette[themeMode].textShadow
    } as const;


    const handleClick = useCallback((event, value) => {
        if (event.target.id === 'themeModeSwitch') {
            dispatch(changeThemeMode());
            return;
        }

        dispatch(changeToView(value));
    }, [dispatch]);

    return (
        <BottomNavigation onChange={handleClick} sx={navCss}>
            <BottomNavigationAction
                sx={buttonCss}
                showLabel={true}
                value={CurrentView.CURRENT_CONDITIONS}
                label={'Current'}
                icon={<WbSunnySharpIcon />}
            />

            <BottomNavigationAction
                sx={buttonCss}
                showLabel={true}
                value={CurrentView.FORECAST}
                label={'Forecast'}
                icon={<CalendarTodaySharpIcon />}
            />
        </BottomNavigation>
    )
}

export default NavBar;
