import React, { FC } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import IForecastInfo from '../../wxApi/IForecastInfo';
import theme from '../../theme/default';
import { useAppSelector } from '../../state/hooks';
import { selectThemeMode } from '../../state/contentSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faCloud,
  faCloudMoon,
  faCloudRain,
  faCloudShowersHeavy,
  faCloudSun,
  faMoon,
  faSnowflake,
  faSun,
  faWind
} from '@fortawesome/free-solid-svg-icons';

type ForecastItemType = {
    item: IForecastInfo
}

type ElementDictionary = {
    [key: string]: {
        [key: string]: {}
    }
}

const iconCss = {
  aspectRatio: '1',
  height: '70%',
  width: 'auto'
} as const;

const conditions: ElementDictionary = {
    night: {
        bkn: <FontAwesomeIcon icon={faCloudMoon} style={iconCss} />,
        few: <FontAwesomeIcon icon={faMoon} style={iconCss} />,
        ovc: <FontAwesomeIcon icon={faCloud} style={iconCss} />,
        rain: <FontAwesomeIcon icon={faCloudRain} style={iconCss} />,
        rain_showers: <FontAwesomeIcon icon={faCloudShowersHeavy} style={iconCss} />,
        sct: <FontAwesomeIcon icon={faCloudMoon} style={iconCss} />,
        skc: <FontAwesomeIcon icon={faMoon} style={iconCss} />,
        snow: <FontAwesomeIcon icon={faSnowflake} style={iconCss} />,
        tsra: <FontAwesomeIcon icon={faBolt} style={iconCss} />,
        wind: <FontAwesomeIcon icon={faWind} style={iconCss} />
    },
    day: {
        bkn: <FontAwesomeIcon icon={faCloudSun} style={iconCss} />,
        few: <FontAwesomeIcon icon={faSun} style={iconCss} />,
        ovc: <FontAwesomeIcon icon={faCloud} style={iconCss} />,
        rain: <FontAwesomeIcon icon={faCloudRain} style={iconCss} />,
        rain_showers: <FontAwesomeIcon icon={faCloudShowersHeavy} style={iconCss} />,
        sct: <FontAwesomeIcon icon={faCloudSun} style={iconCss} />,
        skc: <FontAwesomeIcon icon={faSun} style={iconCss} />,
        snow: <FontAwesomeIcon icon={faSnowflake} style={iconCss} />,
        tsra: <FontAwesomeIcon icon={faBolt} style={iconCss} />,
        wind: <FontAwesomeIcon icon={faWind} style={iconCss} />
    }
}

const parseIcon  = (iconUrl: string) => {
    const re = new RegExp(/^https:\/\/api.weather.gov\/icons\/land\/(?<tod>day|night)\/(?<type>\w+).*$/gim);
    const matches = re.exec(iconUrl);
    const tod = matches ? matches[1] : 'day';

    let cond = matches ? matches[2] : 'skc';
    const condSplit = cond.split('_');
    cond = condSplit[0]

    return conditions[tod][cond];
}

const ForecastItem: FC<ForecastItemType> = props => {
    const currentTheme = useAppSelector(selectThemeMode);
    const { item } = props;

    return (
        <Paper variant={'outlined'} square sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',

            padding: theme.sizes.margin,
            backgroundColor: theme.palette[currentTheme].colors.bg,
            color: theme.palette[currentTheme].colors.fg,

            paddingBottom: theme.sizes.margin
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    { parseIcon(item.icon) }
                </Box>
                <Box sx={{ flex: 2 }}>
                    <Typography sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'bold'
                    }}>
                        {item.name}
                    </Typography>
                    <Typography>
                        {item.shortForecast}
                    </Typography>
                </Box>
                <Typography sx={{
                    flex: 1,
                    fontSize: '2rem'
                }}>
                    {item.temperature}° {item.temperatureUnit}
                </Typography>
            </Box>

            <Typography>{item.detailedForecast}</Typography>
        </Paper>
    )
}

export default ForecastItem;
