import React, { FC } from 'react';
import CenterFocusWeakSharpIcon from '@mui/icons-material/CenterFocusWeakSharp';

const Crosshair: FC = () => {
    const crosshairCss = {
        position: 'absolute',
        top: 'calc(50% - 8px)',
        left: 'calc(50% - 8px)',
        width: '16px',
        zIndex: 9999,
        fontWeight: 700,
    } as const;


    return (
        <CenterFocusWeakSharpIcon sx={crosshairCss} />
    )
}

export default Crosshair;
