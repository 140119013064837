import React from 'react';
import { FC } from 'react';
import { Box } from '@mui/material';
import IForecastInfo from '../../wxApi/IForecastInfo';
import { ForecastItem } from './index';

type ForecastItemListProps = {
    items: IForecastInfo[]
}

const ForecastItemList: FC<ForecastItemListProps> = props => {
    const { items } = props;
    return (
        <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        }}>
            { items.map(fi => <ForecastItem item={fi} key={ fi.number } />) }
        </Box>
    )
}

export default ForecastItemList;
