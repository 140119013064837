import React from 'react';
import './App.css';
import Content from './features/content';
import NavBar from './features/navBar';
import TitleBar from './features/titleBar';

function App() {
  return (
    <div className="App">
        <TitleBar />
        <Content />
        <NavBar />
    </div>
  );
}

export default App;
