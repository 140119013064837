import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import INearbyStation from "../wxApi/INearbyStation";
import IPointInfo from "../wxApi/IPointInfo";
import IForecastInfo from '../wxApi/IForecastInfo';

type TWeatherSlice = {
    pointInfo?: IPointInfo
    nearbyStations?: INearbyStation[],
    forecast?: IForecastInfo[]
}

const initialState: TWeatherSlice = {}

export const weatherSlice = createSlice({
    name: 'weather',
    initialState,
    reducers: {
        setPointInfo: (state, action: PayloadAction<IPointInfo>) => { state.pointInfo = action.payload; },
        setNearbyStations: (state, action: PayloadAction<INearbyStation[]>) => { state.nearbyStations = action.payload },
        setForecastInfo: (state, action: PayloadAction<IForecastInfo[]>) => { state.forecast = action.payload; }
    }
});

export default weatherSlice.reducer;
export const { setPointInfo, setNearbyStations, setForecastInfo } = weatherSlice.actions;

const distance = (x1: number, y1: number, x2: number, y2: number): number => {
    const sqrX = Math.pow((x2 - x1), 2);
    const sqrY = Math.pow((y2 - y1), 2);
    return Math.sqrt(sqrX + sqrY);
}

export const selectCurrentPointInfo = (state: RootState): IPointInfo | undefined => state.weather.pointInfo;
export const selectNearbyStations = (state: RootState): INearbyStation[] => {
    const { latitude, longitude } = state.map.currentPosition;
    const { nearbyStations } = state.weather;

    let stations: INearbyStation[] = [];

    if (nearbyStations) {
        stations = nearbyStations.map((s) => ({
            ...s,
            dist: distance(longitude, latitude, s.lng, s.lat)
        }));
    }

    return stations.sort((a, b) => {
        if (a.dist && b.dist) {
            return a.dist - b.dist;
        }

        return 0;
    });
}
export const selectNearestStation = (state: RootState): INearbyStation | null => {
    const nearbyStations = selectNearbyStations(state);
    if (nearbyStations.length > 0) {
        return nearbyStations[0];
    }

    return null;
}

export const selectForecast = (state: RootState): IForecastInfo[] => state.weather.forecast ?? [];
