import React from 'react';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { changeThemeMode, selectThemeMode, ThemeMode } from '../../state/contentSlice';
import LightbulbSharpIcon from '@mui/icons-material/LightbulbSharp';
import NightlightRoundSharpIcon from '@mui/icons-material/NightlightRoundSharp';
import { useCallback } from 'react';
import StyledFab from './styled-fab';

function DisplayModeFab() {
    const dispatch = useAppDispatch();
    const themeMode = useAppSelector(selectThemeMode);
    const toggleTheme = useCallback(() => {
        dispatch(changeThemeMode())
    }, [dispatch])

    return (
        <StyledFab className={'leaflet-control leaflet-right'} onClick={toggleTheme} sx={{ bottom: 0 }}>
            { themeMode === ThemeMode.LIGHT ? <LightbulbSharpIcon /> : <NightlightRoundSharpIcon /> }
        </StyledFab>
    )
}

export default DisplayModeFab;
