import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export enum CurrentView {
    CURRENT_CONDITIONS,
    FORECAST
}

export enum ThemeMode {
    LIGHT = 'light',
    DARK = 'dark'
}

type TContentSlice = {
    currentView: CurrentView;
    themeMode: ThemeMode;
}

const initialState: TContentSlice = {
    currentView: CurrentView.CURRENT_CONDITIONS,
    themeMode: ThemeMode.LIGHT
}

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        changeToView: (state, action: PayloadAction<CurrentView>) => { state.currentView = action.payload },
        changeThemeMode: (state) => {
            state.themeMode = state.themeMode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK;
        }
    }
});

export const { changeToView, changeThemeMode } = contentSlice.actions;
export const selectCurrentView = (state: RootState) => state.content.currentView;
export const selectThemeMode = (state: RootState) => state.content.themeMode;
export default contentSlice.reducer;
