import bannerLogo_light from '../assets/logo-full.png';
import iconLogo_light from '../assets/logo.png';
import bannerLogo_dark from '../assets/logo-full-inverse.png'

const theme = {
    palette: {
        light: {
            colors: {
                fg: '#5ab0bf',
                bg: '#ffffff',
                accent1: '#458d9f',
                accent2: '#306b7f',
                accent3: '#1c4960',
            },
            logos: {
                banner: bannerLogo_light,
                icon: iconLogo_light
            },
            textShadow: ''
        },
        dark: {
            colors: {
                fg: '#ffffff',
                bg: '#1c4960',
                accent1: '#5ab0bf',
                accent2: '#458d9f',
                accent3: '#306b7f',
            },
            logos: {
                banner: bannerLogo_dark
            },
            textShadow: '0 1px 3px black'
        }
    },
    sizes: {
        margin: '1em',
        marginLarge: '1.5em',
        marginSmall: '0.75em'
    },
    boxShadows: {
        normal: '0 0 4px black'
    }
}

export default theme;
