import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../state/hooks';
import IStationInfo from '../../wxApi/IStationInfo';
import { getStationInfo } from '../../wxApi/providers/wxgov';
import { selectNearestStation } from '../../state/weatherSlice';
import { Card, CardContent, Typography } from '@mui/material';
import theme from '../../theme/default';
import { selectThemeMode } from '../../state/contentSlice';
import * as dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration'
import RelativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(Duration);
dayjs.extend(RelativeTime);

function ctof(value: number) {
    const f = value * 1.8 + 32
    return Math.round(f);
}

function humanizeLastChecked(lastCheckedTimestamp: string): string {
    const lastChecked = new Date(Date.parse(lastCheckedTimestamp));
    const duration = lastChecked.valueOf() - Date.now();
    return dayjs.duration(duration).humanize(true);
}

const CurrentConditions: FC = () => {
    const nearest = useAppSelector(selectNearestStation);
    const [info, setInfo] = useState<IStationInfo | null>();
    const themeMode = useAppSelector(selectThemeMode);

    const { fg, bg } = theme.palette[themeMode].colors;

    const cardCss = {
        position: 'absolute',
        bottom: theme.sizes.marginLarge,
        backgroundColor: bg,
        color: fg,
        textShadow: theme.palette[themeMode].textShadow,
        zIndex: 9999,
        width: '50vw',
    } as const;

    const cardContentCss = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    } as const;

    const tempCss = {
        flex: 3,
        fontSize: '3em'
    } as const;

    const asOfCss = {
        fontSize: '0.75em'
    } as const;

    useEffect(() => {
        if (nearest) {
            getStationInfo(nearest.stationIdentifier).then((i) => { setInfo(i) });
        }
    }, [nearest])

    const nameString = nearest ? nearest.name : 'Unknown Station';
    const tempString = info ? `${ctof(info.temperature)}° F` : 'N/A';
    const timeString = info ? `About ${humanizeLastChecked(info.timestamp)}` : 'Current information not available';

    return (
        <Card className={'leaflet-control'} sx={cardCss} elevation={4}>
            <CardContent sx={cardContentCss}>
                <Typography sx={tempCss}>{tempString}</Typography>
                <Typography sx={asOfCss}>{timeString} at {nameString}</Typography>
            </CardContent>
        </Card>
    )
}

export default CurrentConditions;
